@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");

.ag-theme-material {
  /* use theme parameters where possible */
  --ag-header-background-color: #eff2ff;
  --ag-header-foreground-color: #333333;
  --ag-header-cell-moving-background-color: #c4cae4;
  --ag-header-cell-hover-background-color: #c4cae4;
  --ag-range-selection-border-color: rgba(0, 0, 0, 0);
  --ag-toggle-button-on-background-color: #32418f !important;
  --ag-toggle-button-on-border-color: #32418f;
  /* Font */
  font-family: "Kanit", sans-serif;
  font-size: 14px;
}

.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell {
  background-color: white;
}

.ag-theme-material .ag-header-row:not(:first-child) .ag-header-cell:hover {
  background-color: #f2f2f2 !important;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #32418f !important;
}

.ag-theme-material .ag-radio-button-input-wrapper.ag-checked::after {
  color: #32418f !important;
}

.ag-theme-material .ag-tab-selected {
  border-bottom-color: #32418f;
}

.ag-theme-material
  .ag-ltr
  .ag-side-bar-right
  .ag-selected
  .ag-side-button-button {
  border-bottom-color: #32418f;
}

/* border color of range selecd cells */
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
  border-left-color: #32418f;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
  border-top-color: #32418f;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
  border-right-color: #32418f;
}
.ag-theme-material
  .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
  border-bottom-color: #32418f;
}

/* .ag-theme-material input[class^="ag-"][type="text"] {
  border-bottom-color: #d6cfcf;
} */

.ag-theme-material input[class^="ag-"][type="text"]:focus {
  border-bottom-color: #333333;
}

.ag-theme-material .ag-header-row {
  font-size: 14px;
}

/* .ag-theme-material .ag-root-wrapper {
  border-radius: 15px !important;
  border: 1px solid #d6cfcf;
}

.ag-theme-material .ag-header {
  border: unset !important;
} */

/* .ag-theme-material .ag-details-row {
  background-color: #f7faf8;
} */

.ag-cell-wrapper {
  display: flex;
  align-items: center;
}

.small-padding {
  padding: 0 8px !important;
}

.ag-theme-material .ag-ltr .ag-row-drag {
  margin-right: 12px;
}

.center {
  justify-content: center !important;
  text-align: center !important;
}

.pdf {
  font-size: 12px;
  padding: 0 !important;
}

.pdf-info {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.double-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "center";
}