@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Kanit", sans-serif;
}

.date-picker,
.date-picker > div.react-datepicker-wrapper,
.date-picker
  > div
  > div.react-datepicker__input-container
  .date-picker
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.ag-watermark {
  display: none !important;
}

@media print {
  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    height: initial !important;
    overflow: initial !important;
  }

  @page {
    size: 297mm 210mm;
    margin-bottom: 4rem;
  }
}
